import { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import ProductDefaultImage from '../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../Assets/Products/productDefaultIMage.webp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { BASE_URL_SANDBOX } from '../../Constants/Config';
import { Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ContextSearch } from '../../Context/SearchContext';

const SearchBox = ({
  filterProducts,
  checkCategoryProducts,
  setSearchTerm,
  searchTerm,
  showSearchResults,
  setShowSearchResults,
  forMobile,
}) => {
  const { checkOrderMethod } = useContext(ContextSearch);
  const navigate = useNavigate();
  const resultContainer = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const resetSearchComplete = () => {
    setSearchTerm('');
    setShowSearchResults(false);
  };

  useEffect(() => {
    if (!resultContainer.current) return;
    resultContainer.current.scrollIntoView({
      block: 'center',
    });
  }, [focusedIndex]);

  const handleKeyDown = (e) => {
    const { key } = e;
    let nextIndexCount = 0;

    // move down
    if (key === 'ArrowDown')
      nextIndexCount = (focusedIndex + 1) % filterProducts.length;

    // move up
    if (key === 'ArrowUp')
      nextIndexCount =
        (focusedIndex + filterProducts.length - 1) % filterProducts.length;

    // hide search results
    if (key === 'Escape') {
      resetSearchComplete();
    }

    // select the current item
    if (key === 'Enter') {
      e.preventDefault();
      handleSelection(focusedIndex);
    }

    setFocusedIndex(nextIndexCount);
  };

  const handleSelection = (selectedIndex) => {
    const selectedItem = filterProducts[selectedIndex];
    if (!selectedItem) return resetSearchComplete();

    resetSearchComplete();

    if (selectedItem.merchant_id) {
      navigate(
        `/merchant/${selectedItem.merchant_id}?orderMethod=${checkOrderMethod(
          selectedItem,
        )}`,
      );
    } else {
      checkCategoryProducts(selectedItem?.name);
    }
  };

  const isBool = (index) =>
    filterProducts && filterProducts.length - 1 === index;

  return (
    <div
      tabIndex={1}
      onBlur={resetSearchComplete}
      onKeyDown={handleKeyDown}
      className={`relative`}
      style={{
        display: forMobile ? 'none' : 'block',
      }}
    >
      <div className={`order-top-bar-search`}>
        <Input
          type="search"
          className="order-top-bar-search-input"
          placeholder="Search Categories and Stores"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
        <SearchIcon className="order-top-bar-search-icon" />
      </div>

      <div
        className="home-search-data-box"
        id="home-search-data-box"
        style={{ display: showSearchResults ? 'block' : 'none' }}
      >
        {filterProducts && filterProducts.length >= 1 ? (
          filterProducts.map((product, index) =>
            product.merchant_id ? (
              <div
                key={index}
                onMouseDown={() => handleSelection(index)}
                ref={index === focusedIndex ? resultContainer : null}
                className="home-search-items"
                style={{
                  backgroundColor:
                    index === focusedIndex ? 'rgba(0,0,0,0.04)' : '',
                  borderBottom: isBool(index) ? '0px' : '1px solid #E1E1E1',
                }}
              >
                <span className="logo-image-wrapper">
                  <img
                    onError={(e) => {
                      e.target.src = ProductDefaultImage;
                    }}
                    src={
                      product.img
                        ? `${BASE_URL_SANDBOX}upload/${product.img}`
                        : ProductDefaultImage
                    }
                    alt=""
                  />
                  <Link
                    className="search-bar-link"
                    to={`/merchant/${
                      product.merchant_id
                    }?orderMethod=${checkOrderMethod(product)}`}
                  >
                    {' '}
                    {product?.name}
                  </Link>
                </span>
                <ChevronRightIcon />
              </div>
            ) : (
              <div
                key={index}
                onMouseDown={() => handleSelection(index)}
                ref={index === focusedIndex ? resultContainer : null}
                className="home-search-items"
                style={{
                  backgroundColor: index === focusedIndex ? '#F6F7F8' : '',
                  borderBottom: isBool(index) ? '0px' : '1px solid #E1E1E1',
                }}
              >
                <img
                  src={
                    product.media
                      ? `${BASE_URL_SANDBOX}upload/defaults_images/${product.media}`
                      : `url(${ProductDefaultImage})`
                  }
                  alt=""
                />

                <span
                  className="search-bar-link cursor-pointer"
                  onClick={() => {
                    checkCategoryProducts(product?.name);
                  }}
                >
                  {product?.name}
                </span>
              </div>
            ),
          )
        ) : (
          <p className="search-bar-link">No Category or Store found</p>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
