import React, { useEffect, useState } from 'react';
import Zoom from 'react-img-zoom';
import {
  BASE_URL_SANDBOX,
  PRODUCT_IMAGES_BASE_URL,
  BUCKET_BASE_URL
} from '../../../Constants/Config';
// import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.webp';

const ImageZoom = ({ merchant_id, image }) => {
  const [key, setKey] = useState(0);
  const [imagedisplayCheck, setImageDisplay] = useState(false);

  function isBase64URL(str) {
    const regex = /^data:[\w\/\+]+;base64,([\w\/\+=]+)$/;
    return regex.test(str);
  }
  // useEffect(() => {
  //   console.log('image: ', image);
  //   let data = isBase64URL(image);
  //   console.log('Data: ', data);
  //   setImageDisplay(data);
  //   setKey((prevKey) => prevKey + 1);
  // }, [merchant_id, image]);

  useEffect(() => {
    // console.log('image: ', image);
    setKey((prevKey) => prevKey + 1);
    setImageDisplay(() => false);

    if (image) {
      const img = new Image();
      img.src = `${BUCKET_BASE_URL}upload/products/${merchant_id}/${image}`;

      // console.log('complete: ', img.complete);

      if (img.complete) {
        setImageDisplay(() => true);
      } else {
        img.onload = () => {
          console.log('ok..');
          setImageDisplay(() => true);
        };

        img.onerror = () => {
          console.log('not ok.');
          setImageDisplay(() => false);
        };
      }
    }
  }, [merchant_id, image]);

  // console.log(
  //   'condition: ',
  //   image && imagedisplayCheck,
  //   imagedisplayCheck,
  //   key,
  //   image,
  // );

  return (
    <>
      <div className="single-product-zoom-div">
        <Zoom
          key={key}
          // img={
          //   image
          //     ? imagedisplayCheck == false
          //       ? `${BASE_URL_SANDBOX}upload/products/${merchant_id}/${image}`
          //       : ProductDefaultImage
          //     : ProductDefaultImage
          // }
          img={
            image && imagedisplayCheck
              ? `${BUCKET_BASE_URL}upload/products/${merchant_id}/${image}`
              : ProductDefaultImage
          }
          // onerror={() => console.log('hehe error')}
          zoomScale={2}
          transitionTime={0.5}
          width={400}
          height={428}
        />
      </div>
    </>
  );
};

export default ImageZoom;
