import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  cartData: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.cartData = [...state.cartData, ...action.payload];
    },
    setCartData: (state, action) => {
      state.cartData = action.payload;
    },
    setCartDataOld: (state, action) => {
      state.cartData = action.payload;
    },
    removeCartItem: (state, action) => {
      state.cartData = state.cartData.filter(
        (item) => item.cartProductId !== action.payload.cartProductId,
      );
    },
    updateCartItem: (state, action) => {
      const index = state.cartData.findIndex(
        (item) => item.cartProductId === action.payload.cartProductId,
      );
      if (index !== -1) {
        // You can update the quantity in action.payload.quantity or use another value
        state.cartData[index].quantity = action.payload.quantity;
        state.cartData[index].isOutOfStock = false;
        state.cartData[index].msg = '';
        state.cartData[index].isDisabled = false;
      }
    },
    updateCartItemfromSingleProduct: (state, action) => {
      const index = state.cartData.findIndex(
        (item) => item.productId === action.payload.productId,
      );
      if (index !== -1) {
        // You can update the quantity in action.payload.quantity or use another value
        state.cartData[index].quantity = action.payload.quantity;
      }
    },
    updateCartWithVariants: (state, action) => {
      // console.log('updateCartWithVariants: ', action.payload);
      state.cartData = action.payload;
    },
  },
});

export const {
  setCartData,
  addToCart,
  setCartDataOld,
  removeCartItem,
  updateCartItem,
  updateCartItemfromSingleProduct,
  updateCartWithVariants,
} = cartSlice.actions;

export default cartSlice.reducer;
