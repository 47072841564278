import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_SINGLE_STORE_PRODUCT_DATA,
  GET_SINGLE_STORE_PRODUCT_DATA_ELASTIC,
  BASE_URL_FOR_ELASTIC_SEARCH
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  storeProductData: {},
  error: '',
};
let offset = 0
// Generate pening , fulfilled and rejected action type
export const fetchSingleStoreProductData = createAsyncThunk(
 
  'storeProductData/fetchSingleStoreProductData',
  async (data) => {
    offset = data.offset
    
    try {
      const response = await axios.post(
        BASE_URL_FOR_ELASTIC_SEARCH + GET_SINGLE_STORE_PRODUCT_DATA_ELASTIC,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      if (response.data.status === 200) {
        return response.data.result;
      } else throw new Error(`Server error`);
    } catch (error) {
      new Error(error.message);
    }
  },
);
export const fetchSingleStoreProductDataOld = createAsyncThunk(
 
  'storeProductData/fetchSingleStoreProductData',
  async (data) => {
    
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_SINGLE_STORE_PRODUCT_DATA,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      if (response.data.status === 200) {
        return response.data.result;
      } else throw new Error(`Server error`);
    } catch (error) {
      new Error(error.message);
    }
  },
);

const singleStoreProductDataSlice = createSlice({
  name: 'storeProductData',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSingleStoreProductData.pending, (state) => {
  if(offset==0){
    state.loading = true
  }
   
    });
    builder.addCase(fetchSingleStoreProductData.fulfilled, (state, action) => {
      let arr = [...(state.storeProductData?.all_collection || []), ...action.payload.all_collection];
      let productArry = {...state.storeProductData?.all_product , ...action?.payload?.all_product };
      state.storeProductData = {
        ...state.storeProductData,
        all_collection: arr,
        all_banners :action.payload.all_banners || [],
        all_product:productArry
    }
      state.loading = false;
      state.error = '';
    });
    builder.addCase(fetchSingleStoreProductData.rejected, (state, action) => {
      state.loading = false;
      state.storeProductData = {};
      state.error = action.error.message;
    });
  },
});

export default singleStoreProductDataSlice.reducer;
