import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL_SANDBOX, GET_SINGLE_STORE_DATA_NEW,GET_SINGLE_STORE_DATA_NEW_ELASTIC,BASE_URL_FOR_ELASTIC_SEARCH ,GET_SINGLE_STORE_DATA_NEW_WITH_TIME} from '../../../Constants/Config';

const initialState = {
    loading: false,
    storesData: {},
    error: '',
};

// Generate pening , fulfilled and rejected action type
export const fetchSingleStoresData = createAsyncThunk('singleStores/fetchSingleStoreData',
    async (data) => {
        try {
            const response = await axios.post(
                BASE_URL_FOR_ELASTIC_SEARCH + GET_SINGLE_STORE_DATA_NEW_ELASTIC,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                },
            );
            if (response.data.status === 200) {
                return response.data.result;
            } else throw new Error(`Server error`);
        } catch (error) {
            new Error(error.message);
        }
    },
);
export const fetchSingleStoresDataWithTime = createAsyncThunk('singleStores/fetchSingleStoresDataWithTime',
    async (data) => {
        try {
            const response = await axios.post(
                BASE_URL_SANDBOX + GET_SINGLE_STORE_DATA_NEW_WITH_TIME,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                },
            );
            if (response.data.status === 200) {
                return response.data.result;
            } else throw new Error(`Server error`);
        } catch (error) {
            new Error(error.message);
        }
    },
);
export const fetchSingleStoresDataOld = createAsyncThunk('singleStores/fetchSingleStoreData',
    async (data) => {
        try {
            const response = await axios.post(
                BASE_URL_SANDBOX + GET_SINGLE_STORE_DATA_NEW,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                },
            );
            if (response.data.status === 200) {
                return response.data.result;
            } else throw new Error(`Server error`);
        } catch (error) {
            new Error(error.message);
        }
    },
);

const singleStoresSlice = createSlice({
    name: 'singleStores',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchSingleStoresData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchSingleStoresData.fulfilled, (state, action) => {
            state.loading = false;
            state.storesData = action.payload;
            state.error = '';
        });
        builder.addCase(fetchSingleStoresData.rejected, (state, action) => {
            state.loading = false;
            state.storeData = {};
            state.error = action.error.message;
        });

        builder.addCase(fetchSingleStoresDataWithTime.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchSingleStoresDataWithTime.fulfilled, (state, action) => {
            state.loading = false;
            if (action?.payload?.store_data) {
                state.storesData = {
                    ...state.storesData,
                    store_data: {
                        ...state?.storesData?.store_data,
                        ...action?.payload?.store_data,
                    },
                };
            } else {
                state.storesData = {
                    ...state.storesData,
                };
            }
        
            state.error = '';
        });
        
        builder.addCase(fetchSingleStoresDataWithTime.rejected, (state, action) => {
            state.loading = false;
            state.storeData = {};
            state.error = action.error.message;
        });
    },
});

export default singleStoresSlice.reducer;
